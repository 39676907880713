<template>
  <div v-if="obItem" v-frag>
    <div v-if="inline" class="d-flex align-center">
      <span class="font-weight-bold" v-text="obItem.code" />
      <span
        class="d-inline-block ml-3 font-weight-light text-truncate"
        v-text="obItem.name"
      />
    </div>
    <v-list v-else color="transparent" dense>
      <v-list-item-content>
        <v-list-item-title :class="titleClass" v-text="obItem.name" />
        <v-list-item-subtitle :class="subtitleClass" v-text="obItem.code" />
      </v-list-item-content>
    </v-list>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { AccountData } from "@planetadeleste/vue-mc-gw";
import { find } from "lodash";
import { AccountModule } from "@/store/account";

@Component
export default class AccountCodePreview extends Vue {
  @Prop(Object) readonly item!: AccountData;
  @Prop(String) readonly code!: string;
  @Prop(Boolean) readonly inline!: boolean;
  @Prop(Boolean) readonly small!: boolean;

  get obItem(): Partial<AccountData> | undefined {
    return this.item
      ? this.item
      : find(AccountModule.items, { code: this.code });
  }

  get titleClass() {
    return {
      "text-subtitle-2": this.small,
    };
  }

  get subtitleClass() {
    return {
      "text-caption": this.small,
    };
  }
}
</script>
